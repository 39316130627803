import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import UsePagination from "../../components/UsePagination";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { Divider, Empty } from "antd";

const Winners = () => {
  useEffect(() => {
    window.scroll({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  const authToken = localStorage.getItem("userToken");

  const [confirmModal, setconfirmModal] = useState(false);
  const [wallapersList, setwallapersList] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [links, setlinks] = useState([]);
  const [numberofpages, setnumberofpages] = useState(0);
  const [isEdit, setIsEdit] = useState(1);
  const [total, settotal] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [text, setText] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [show2,setShow2]=useState(false)
  const [file,setFile]=useState(null)
  const handleClose = () => {
    setText('')
    setShow(false)};
  const handleClose2 = () => {
    setText('')
    setShow2(false)};
  const handleShow = () => setShow(true);
  const [selectedWal, setSelectedWal] = useState(null);
  //multiple select states
  const [MasterChecked, setMasterChecked] = useState(false);
  const [SelectedList, setSelectedList] = useState([]);
  const [confirmModal2,setconfirmModal2]=useState(false)
  const handleChangeImages = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";

    imagesArray.push(img);
    setimages(imagesArray);
    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);
    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }
  };

  const [myWinner, setMyWinner] = useState(null);
  const [dummyWinner, setDummyWinner] = useState(null);

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {
    getrequestedWallapers(`${baseUrl}/admin/approved-requested-wallpapers`);
    getWinner();
    getDefaultWinner()
  }, []);

  const getWinner = () => {
    fetch(`${baseUrl}/admin/winners`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.metadata){
          if(data.metadata.success){
            setMyWinner(data?.payload);
          }else{
            setMyWinner(null);

          }
         }
        
      })
      .catch((err) => {
        setMyWinner(null);
        console.log(err);
      });
  };

  const getDefaultWinner = () => {
    fetch(`${baseUrl}/admin/dummy-winners`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
       if(data.metadata){
        if(data.metadata.success){
          setDummyWinner(data?.payload);
        }else{
          setDummyWinner(null)
         }
       }
      })
      .catch((err) => {
        setDummyWinner(null);
        console.log(err);
      });
  };

  const getrequestedWallapers = (url) => {
    enableLoading();
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let filteredArr = data.payload.data;

        let checkboxes = filteredArr;
        filteredArr.map((item, index) => {
          item["selected"] = false;
          checkboxes[index] = item;
        });
        setwallapersList([...checkboxes]);
        setnumberofpages(data.payload.per_page);
        settotal(data.payload.total);
        setlinks(data.payload.links);
        console.log(checkboxes);
        disabledLoading();
      })
      .catch((err) => {
        disabledLoading();
        console.log(err);
      });
  };

  const Underdevelopment = () => {
    toast.error("Under Development");
  };

  const enableLoading = () => {
    setloading(true);
  };

  const disabledLoading = () => {
    setloading(false);
  };

  // const allcheckboxChecked = ()=>{

  //   const allcheckboxChecked = () => {
  //     let middlearray = [];
  //     let staffarray = [];

  //     if (checkallcheckbox) {
  //         setcheckallcheckbox(false);
  //         allstudentList.map((item, i) => {
  //             item.checked = false;
  //             middlearray.push(item);
  //         });
  //         // generateSalary()
  //         setselectedStudents([]);
  //     } else {
  //         setcheckallcheckbox(true);
  //         allstudentList.map((item, i) => {
  //             item.checked = true;
  //             staffarray.push(item.id);
  //             middlearray.push(item);
  //         });
  //         setselectedStudents([...staffarray]);

  //         // generateSalary()
  //     }
  //     // console.log(selectedcampusstaff);
  //     setallstudentsList([...middlearray]);
  // };
  // }

  const onPaginate = (link) => {
    getrequestedWallapers(link?.url);
    sessionStorage.setItem("epic_remember_url2", link.url);
  };

  // multiple select
  const onMasterCheck = (e) => {
    let tempList = wallapersList;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    let filtered = wallapersList.filter((e) => e.selected);
    setwallapersList(tempList);
    setMasterChecked(e.target.checked);
    setSelectedList(filtered);

    console.log(filtered);
  };

  const onItemCheck = (e, item) => {
    console.log("event is", e.target.checked);
    console.log("item is", item);
    let tempList = wallapersList;

    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = wallapersList.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
    setwallapersList(tempList);
    setSelectedList(wallapersList.filter((e) => e.selected));

    console.log(wallapersList.filter((e) => e.selected));
  };

  const cancelSub = () => {
    const data = { caption: text, requested_wallpaper_id: selectedWal };
    const data2 = { caption: text };
    const url = `${baseUrl}/admin/winners`;
    const url2 = `${baseUrl}/admin/winners/${myWinner?.id}?_method=PUT`;
    setSubLoading(true);
    fetch(isEdit ? url2 : url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(isEdit ? data2 : data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setSubLoading(false);
          setShow(false);
          setText("");
          // reload()
          getWinner();
          const rememberLink = sessionStorage.getItem("epic_remember_url2");
          if (rememberLink) {
            getrequestedWallapers(rememberLink);
          } else {
            getrequestedWallapers(
              `${baseUrl}/admin/approved-requested-wallpapers`
            );
          }
        } else if (!data.metadata.success) {
          setSubLoading(false);
          toast.error(data.metadata.message);
        } else {
          setSubLoading(false);
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {
        setSubLoading(false);
        console.log(err);
      });
  };




  const cancelSub2 = () => {
    if(text==''){
      return toast.error('Please enter caption')
    }
    if(!isEdit){
      if(!file){
        return toast.error('Please select the file')
      }
    }
    const formData=new FormData()
    formData.append('caption',text)
    if(file){
      formData.append('image',file)
    }


    const url = `${baseUrl}/admin/dummy-winners`;
    const url2 = `${baseUrl}/admin/dummy-winners/${dummyWinner?.id}?_method=PUT`;
    setSubLoading(true);
    fetch(isEdit ? url2 : url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body:formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setSubLoading(false);
          setShow2(false);
          setText("");
          // reload()
          setFile(null)
          getDefaultWinner();
         
        } else if (!data.metadata.success) {
          setSubLoading(false);
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          setSubLoading(false);
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        setSubLoading(false);
        console.log(err);
      });
  };

  const deleteModal = () => {
    setdeleteloading(true);
    fetch(`${baseUrl}/admin/winners/${myWinner.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        caption: text,
        requested_wallpaper_id: selectedWal,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success("Winner has been successfully removed.");
          setdeleteloading(false);
          setconfirmModal(false);
          getWinner();
          const rememberLink = sessionStorage.getItem("epic_remember_url2");
          if (rememberLink) {
            getrequestedWallapers(rememberLink);
          } else {
            getrequestedWallapers(
              `${baseUrl}/admin/approved-requested-wallpapers`
            );
          }
        } else if (!data.metadata.success) {
          setdeleteloading(false);
          toast.error(data.metadata.message);
        } else {
          setdeleteloading(false);
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {
        setdeleteloading(false);
        console.log(err);
      });
  };
  const deleteModal2 = () => {
    setdeleteloading(true);
    fetch(`${baseUrl}/admin/dummy-winners/${dummyWinner.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success("Default Winner has been successfully removed.");
          setdeleteloading(false);
          setconfirmModal2(false);
          getDefaultWinner();
        } else if (!data.metadata.success) {
          setdeleteloading(false);
          toast.error(data.metadata.message);
        } else {
          setdeleteloading(false);
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {
        setdeleteloading(false);
        console.log(err);
      });
  };

  const downloadImage = async (imageUrl, name) => {
    setLoading2(true);
    try {
      if (!imageUrl) {
        console.error("No image found to download.");
        return;
      }
      const backendUrl = `https://epic-image-download.vercel.app/download?imageUrl=${encodeURIComponent(
        imageUrl
      )}`;
      const response = await fetch(backendUrl, {
        method: "GET",
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <>
      <div className="d-flex" style={{ justifyContent: "end" }}>
        <button disabled={dummyWinner !=null} className="btn btn-primary" onClick={()=>{
          setIsEdit(false)
          setShow2(true)
        }}>Add Default Winner</button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Spinner animation="border" />}
      </div>

      {!loading  && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
            flexWrap: "wrap",
            padding:"10px"
          }}
        >
          <div>
            <div>
              <h2>Winner</h2>
            </div>
            <div class=" mb-5 repsonsive-media" style={{ width: "380px" }}>
              <div class="card" style={{ borderRadius: "20px" }}>
             {myWinner?.image || !myWinner ? <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"385px"}}>
              <Empty description="No Data"/>
             </div>:    <img
                  height={300}
                  loading="lazy"
                  style={{
                    borderStartStartRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  src={myWinner?.requested_wallpaper?.image?.original_url}
                  alt="image 1"
                />
}
                <div
                  style={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <h4>{myWinner?.image ? '' :  myWinner?.caption}</h4>
                  {myWinner?.image || !myWinner ? '' : <Divider />}
               {myWinner?.image || !myWinner ? '' :   <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div>
                      {loading2 ? (
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <Spinner
                            size="sm"
                            variant="dark"
                            animation="border"
                          />
                        </span>
                      ) : (
                        <DownloadOutlined
                          onClick={() =>
                            downloadImage(
                              myWinner?.requested_wallpaper?.image
                                ?.original_url,
                              "Winner"
                            )
                          }
                          style={{ fontSize: "1.5rem" }}
                        />
                      )}
                    </div>
                    <div>
                      <DeleteOutlined
                        onClick={() => setconfirmModal(true)}
                        style={{ fontSize: "1.5rem", color: "red" }}
                      />
                    </div>
                    <div>
                      <EditOutlined
                        style={{ fontSize: "1.5rem", color: "blue" }}
                        onClick={() => {
                          setText(myWinner?.caption);
                          setIsEdit(true);
                          setShow(true);
                        }}
                      />
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>

          {dummyWinner !=null && <div>
            <div>
              <h2>Default Winner</h2>
            </div>
            <div class=" mb-5 repsonsive-media" style={{ width: "380px" }}>
              <div class="card" style={{ borderRadius: "20px" }}>
                <img
                  height={300}
                  loading="lazy"
                  style={{
                    borderStartStartRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  src={dummyWinner?.image?.original_url}
                  alt="image 1"
                />

                <div
                  style={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <h4>{dummyWinner?.caption}</h4>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div>
                      {loading2 ? (
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <Spinner
                            size="sm"
                            variant="dark"
                            animation="border"
                          />
                        </span>
                      ) : (
                        <DownloadOutlined
                          onClick={() =>
                            downloadImage(
                              dummyWinner?.image
                                ?.original_url,
                              "Default Winner"
                            )
                          }
                          style={{ fontSize: "1.5rem" }}
                        />
                      )}
                    </div>
                    <div>
                      <DeleteOutlined
                        onClick={() => setconfirmModal2(true)}
                        style={{ fontSize: "1.5rem", color: "red" }}
                      />
                    </div>
                    <div>
                      <EditOutlined
                        style={{ fontSize: "1.5rem", color: "blue" }}
                        onClick={() => {
                          setText(dummyWinner?.caption);
                          setIsEdit(true);
                          setShow2(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      )}
      <Divider />
      <h2>Select Winner</h2>
      <Divider />

      <div className="row">
        {wallapersList &&
          wallapersList.map((item) => (
            <div
              class="col-lg-2 mb-5 repsonsive-media"
              style={{ width: "280px" }}
            >
              <div class="card" style={{ borderRadius: "20px" }}>
                <img
                  height={300}
                  loading="lazy"
                  style={{
                    borderStartStartRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  src={item.image?.original_url}
                  alt="image 1"
                />

                <div
                  style={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      disabled={item?.is_winner}
                      onClick={() => {
                        setText("");
                        setIsEdit(false);
                        setSelectedWal(item.id);
                        setShow(true);
                      }}
                      className="btn btn-success"
                    >
                      {item?.is_winner ? "Winner" : "Select Winner"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <UsePagination links={links} onPaginate={onPaginate} />
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        {
          links.map((link, index) => (
            <button
              className={`btn m-2 ${link.active ? 'btn-primary' : 'btn-info'}`}
              disabled={link.url == null || link.active ? true : false}
              onClick={() => onPaginate(link)}>
              <div>{link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}</div>
            </button>
          ))
        }
      </div> */}

      {/* add new modal start */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="md"
        centered
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Winner" : "Winner"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col-md-12">
            <div>
              <label>
                <b>Caption</b>
              </label>
            </div>
            <textarea
              onChange={(e) => setText(e.target.value)}
              value={text}
              className="form-control"
              rows={4}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button disabled={subLoading} variant="primary" onClick={cancelSub}>
            {!isEdit ? "Save" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/*add new modal end */}

      <Modal centered show={confirmModal}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          {deleteloading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <button
                onClick={() => setconfirmModal(false)}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button onClick={deleteModal} className="btn btn-primary">
                Delete
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal centered show={confirmModal2}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          {deleteloading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <button
                onClick={() => setconfirmModal2(false)}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button onClick={deleteModal2} className="btn btn-primary">
                Delete
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        size="md"
        centered
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Default Winner" : "Default Winner"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col-md-12">
            <div>
              <label>
                <b>Caption</b>
              </label>
            </div>
            <textarea
              onChange={(e) => setText(e.target.value)}
              value={text}
              className="form-control"
              rows={4}
            ></textarea>
          </div>
          <div className="col-md-12 mt-2">
            <div>
              <label>
                <b>Upload Image</b>
              </label>

              <input onChange={(e)=>setFile(e.target.files[0])} type="file" className="form-control" accept="image/*" />
              <div className="mt-5">
               {file ?  <img height={150} width={150} style={{borderRadius:"20px"}} src={URL.createObjectURL(file)}/> : ''}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
            setIsEdit(false)
            setShow2(false)
            setText('')
            setFile(null)
          }}>
            Close
          </Button>
          <Button disabled={subLoading} variant="primary" onClick={cancelSub2}>
            {!isEdit ? "Save" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Winners;
