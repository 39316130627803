import React, { useState, useEffect } from "react";

import { toast } from "react-hot-toast";
import { useFormik } from "formik"
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { baseUrl } from '../../../services/config';
import Upload from "../../Images/upload.png";
import Imagecard from "../Imagecard";

const AddWallpaperModal = ({ visible, handleCloseModal, reload, type, foldable, isFirst }) => {
    const authToken = localStorage.getItem("userToken");
    const [img, setimg] = useState("");
    const [imgError, setimgError] = useState("");
    const [loading, setloading] = useState(false);
    const initialValues = {
        url: !isFirst ? "https://www.google.com" : "",

    }
    const LoginSchema = Yup.object().shape({
        url: Yup.string()
            .required('Url is Required')
            .matches(/^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/,
                "Url must begin with http or https:// and end with .com "
            ),
    });
    const LoginSchema2 = Yup.object().shape({
        url: Yup.string()
            .notRequired('Url is Required')
            .matches(/^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/,
                "Url must begin with http or https:// and end with .com "
            ),
    });
    const saveSide = (values, resetForm) => {
        console.log(values)
        const fdata = new FormData();
        fdata.append("type", type);
        fdata.append("url", isFirst ? "https://www.google.com" : values.url);
        fdata.append("image", img);
        let url1 = "admin/banners";
        let url2 = "admin/fold-able-banners";
        setloading(true);
        fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            body: fdata,
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.metadata.success) {
                    handleCloseModal();
                    setloading(false);
                    reload();
                    resetForm()
                } else {
                    setloading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: !isFirst ? LoginSchema : LoginSchema2,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            if (img) {
                saveSide(values, resetForm)
            } else {
                setimgError('Image is required')
            }
        },
    })
    return (
        <Modal
            show={visible}
            onHide={handleCloseModal}
            animation={false}
            size="lg"
        // style={{ marginLeft: "140px" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'native'
                        ? 'Native'
                        : type === 'reward'
                            ? 'Reward'
                            : 'Side'}
                </Modal.Title>
            </Modal.Header>
            {loading == true ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div class="spinner-border text-dark  p-5 m-5 " role="status">
                        <span class="sr-only ">Loading...</span>
                    </div>
                </div>
            ) : (
                <Modal.Body className="row">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >

                            {
                                // !isFirst && type !== 'native' && type !== 'reward' ? (
                                !isFirst ? (
                                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            name="url"
                                            placeholder="URL"
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                "url"
                                            )}`}
                                            {...formik.getFieldProps("url")}
                                        />
                                        {formik.touched.url && formik.errors.url ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.url}</div>
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                ) : null
                            }
                        <div className="col-12">
                            <div
                                className="avatar-upload-input text-center "
                                style={{ marginTop: "20px" }}
                            >
                                <img src={Upload} alt="img" />
                                <h2 className="mt-3">Upload File</h2>
                                <div className="avatar-edit-input">
                                    <input
                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                        style={{ textAlignLast: "center" }}
                                        type="file"
                                        onChange={(e) => {
                                            setimgError("")
                                            setimg(e.target.files[0])
                                        }}
                                        name="image"
                                        id="imageUpload"
                                        accept=".png, .jpg, .jpeg"

                                    />

                                    {imgError ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{imgError}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {showlist.map((pic, i) => (
                                    <div className="pic" key={i + 1}>
                                        <img
                                            key={i + 1}
                                            height={150}
                                            width={150}
                                            style={{ margin: 5 }}
                                            src={pic}
                                        />
                                        <button
                                            className="danger"
                                            onClick={() => removeImage(i)}
                                            style={{ border: "none" }}
                                        >
                                            <p style={{ color: "red" }}>X</p>
                                        </button>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </form>
                </Modal.Body>
            )}
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button type="submit" variant="primary" onClick={formik.handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default AddWallpaperModal