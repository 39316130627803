import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'
import { Button, message, Space } from 'antd';

const ApproveReject = ({
    HideModal,
    visible,
    id,
    reload,
    url,
    isLocked
}) => {
    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const cancelSub = (status) => {
        setLoading(true)
        fetch(`${baseUrl}/admin/slot-requests/${id}`, {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body:JSON.stringify({status:status})
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    setLoading(false)
                    HideModal()
                    reload()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    toast.error(data.metadata.message)
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }



    return (
        <>
            <Modal
                centered
                show={visible}>
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                </Modal.Header>
               
                            <Modal.Body>
                                {
                                    `Are you sure you want to change status?`
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    onClick={HideModal}
                                    className='btn'>
                                    Cancel
                                </button>

                               {
                                  loading ?
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                                      <Spinner animation='border' color='green' />
                                  </div>
                                  :
                                  <>
                                  <button
                                    onClick={()=>cancelSub('approved')}
                                    className='btn btn-primary'>
                                    Approve
                                </button>
                                <button
                                    onClick={()=>cancelSub('rejected')}
                                    className='btn btn-danger'>
                                    Reject
                                </button>
                                  </>
                               }
                            </Modal.Footer>
             
            </Modal>
        </>
    )
}

export default ApproveReject