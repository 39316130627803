import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { baseUrl } from "../../../services/config";
import { useState } from "react";
import { Card as AntCard, Empty, Skeleton } from "antd";
import { LockOutlined,UnlockOutlined,ReloadOutlined,DownloadOutlined } from "@ant-design/icons";
import LockSlot from "../../components/LockSlot/LockSlot";
import ResetSlot from "../../components/LockSlot/ResetSlot";
import { Spinner } from "react-bootstrap";
const SlotsCard = ({updateSlots,callBack}) => {
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };



  const authToken = localStorage.getItem("userToken");
 const [slots,setSlots]=useState([])
 const [selectedId,setSelectedId]=useState(null)
  const [loading, setLoading] = useState(false);
 const [open,setOpen]=useState(false)
 const [loading2,setLoading2]=useState(false)
 const [open2,setOpen2]=useState(false)
 const [imageUrl,setImageUrl]=useState('')
  const getHistory = (url) => {
    setLoading(true);
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
            setSlots(data.payload)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  useEffect(() => {
    getHistory(`${baseUrl}/admin/slots`);
  }, [updateSlots]);
  function removePaidRequest(input) {
    if(input){
        return input.replace("Paid Request", "").trim();
    }
}
const downloadImage = async (imageUrl, name) => {
  setImageUrl(imageUrl)
  setLoading2(true)
  try {
    if (!imageUrl) {
      console.error("No image found to download.");
      return;
    }
    const backendUrl = `https://epic-image-download.vercel.app/download?imageUrl=${encodeURIComponent(imageUrl)}`;
    const response = await fetch(backendUrl, {
      method: 'GET',
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    setLoading2(false)
  } catch (error) {
    console.error("Error downloading the image:", error);
  }
};

  return (
    <Card>
      <CardHeader title="Slots"></CardHeader>

      <CardBody>
        <div className="row">
          {slots?.map((item) => (
            <div className="col-md-4">
              <Skeleton loading={loading} avatar active>
                <AntCard
                  hoverable
                  style={{
                    margin: 10,
                  }}
                  cover={
                    item.image ?  <img
                    height={250}
                      // onClick={() => gotToCalendar(`/rota-calendar/${item.id}`)}
                      alt="example"
                      src={item.image || 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'}
                    /> : <Empty description="No Image" style={{height:250,display:"flex",justifyContent:"center",flexDirection:"column"}}/>
                  }
                  loading={loading}
                >
                  <div
                    style={{
                      marginLeft: "-15px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h6>{removePaidRequest(item.name) || ''}</h6>
                      <h6>Price: ${item.price || ''}</h6>
                    </div>
                    {/* <Meta
                        title={`Price: 200$`}
                    /> */}
                    <div>
                 { item?.image && (loading2 && imageUrl==item?.image ? <span style={{position:"relative",top:"-5px",marginRight:'10px'}}>
                  <Spinner size="sm" variant="dark" animation="border"/>
                 </span> : <DownloadOutlined  
                    onClick={()=>{
                      downloadImage(item.image,removePaidRequest(item.name))
                      }}
                    style={{fontSize:"1.5rem",marginRight:"10px"}}/>)}
                     <ReloadOutlined
                     onClick={()=>{
                        setSelectedId(item)
                        setOpen2(true)
                      }}
                     style={{fontSize:"1.5rem",marginRight:"10px"}} />
                    {!item.is_locked ?   <LockOutlined onClick={()=>{
                        setSelectedId(item)
                        setOpen(true)
                      }} style={{fontSize:"1.5rem",color:"red"}} className="text-danger" key="Lock" /> :
                      <UnlockOutlined onClick={()=>{
                        setSelectedId(item)
                        setOpen(true)
                      }} style={{fontSize:"1.5rem"}} className="text-primary" key="Lock" />}
                    </div>
                  </div>
                </AntCard>
              </Skeleton>
            </div>
          ))}
        </div>
      </CardBody>
      <LockSlot visible={open} HideModal={()=>setOpen(false)} id={selectedId?.id} reload={()=>{
        callBack()
        getHistory(`${baseUrl}/admin/slots`)}
        } isLocked={selectedId?.is_locked}/>
      <ResetSlot visible={open2} HideModal={()=>setOpen2(false)} id={selectedId?.id} reload={()=>{
        callBack()
        getHistory(`${baseUrl}/admin/slots`)}}/>
    </Card>
  );
};

export default SlotsCard;
