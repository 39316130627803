import React, { useEffect, useMemo } from "react";
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls'
import { baseUrl } from "../../../services/config";
import { useState } from "react";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { DatePicker, Image, Space, Tag } from 'antd';
import { Table } from "react-bootstrap";
import usePagination from "../../components/usePagination/usePagination";
import Pagination from "@material-ui/lab/Pagination";
import { CSVLink } from "react-csv";
import SlotsCard from "./SlotsCards";
import UsePagination from "../../components/UsePagination";
import ApproveReject from "../../components/ApproveReject/ApproveReject";


const { RangePicker } = DatePicker;

const { ExportCSVButton } = CSVExport;


const Slots = () => {


    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };

    const [queryParams, setQueryParamsBase] = useState(initialFilter);




    const updatingQueryParams = (page) => {
        console.log(page);
        setQueryParamsBase({
            pageNumber: page,
        });
    };

    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    };

    const authToken = localStorage.getItem('userToken')

    const [loading, setLoading] = useState(false)
    const [rewardsList, setRewardList] = useState([])
    const [links, setlinks] = useState([]);
    const [numberofpages, setnumberofpages] = useState(0);
    const [total, settotal] = useState(0);
    const [dateChangeData, setDateChangeData] = useState([])
    const [date, setDate] = useState([])
    const [csvList, setCsvList] = useState([]);
    const [open,setOpen]=useState(false)
    const [selectedId,setSelectedId]=useState(null)
    const [updateSlots,setUpdateSlots]=useState(false)
    const [prevUrl,setPrevUrl]=useState()
    const getHistory = (url) => {
        setPrevUrl(url)
        setLoading(true)
        setDateChangeData([])
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setRewardList(data.payload.data)
                    setnumberofpages(data.payload.per_page);
                    settotal(data.payload.total);
                    setlinks(data.payload.links)
                    setLoading(false)
                } else {
                    setLoading(false)

                    setRewardList([])
                }
            }).catch(err => {
                setLoading(false)

                console.log(err);
            })
    }



    useEffect(() => {
        getHistory(`${baseUrl}/admin/all-slot-requests`)
    }, [])

    const onPaginate = (link) => {
        getHistory(link?.url);
    };








    let [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const count = Math.ceil(dateChangeData.length / PER_PAGE);
    const _DATA = usePagination(dateChangeData, PER_PAGE);


    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const getClassOrder = (row) => {
        let classname = "";
        if (row == "pending") {
          classname = "#fa0";
        } else if (row == "approved") {
          classname = "#2db7f5";
        }else{
          classname = "red";
        }
    
        return classname;
      };
    
    console.log("csvArray", csvList);
    function capitalizeFirstLetter(string) {
        if (!string) return ""; // Handle empty or undefined strings
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const callBack=()=>{
        getHistory(prevUrl)
    }
    function removePaidRequest(input) {
        if(input){
            return input.replace("Paid Request", "").trim();
        }
    }
    return (
        <>
        <SlotsCard callBack={callBack} updateSlots={updateSlots}/>
        <Card>
        <CardHeader title="Slots Requests">
        </CardHeader>

            <CardBody>


                {loading && <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div class="spinner-border">
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                </div>}
                {
                    rewardsList.length > 0 ?
                        <>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Image</th>
                                        <th>FIRST NAME</th>
                                        <th>LAST NAME</th>
                                        <th>EMAIL</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rewardsList?.map((item) => (
                                            <tr>
                                                <td>{removePaidRequest(item?.slot?.name)}</td>
                                                <td>
                                                <Image
                                                style={{borderRadius:"50%"}}
    width={40}
    height={40}
    src={item?.image_url}
  />
                                                </td>
                                                <td>{item?.user?.first_name}</td>
                                                <td>{item?.user?.last_name}</td>
                                                <td>{item?.user?.email}</td>
                                                <td>

                                                <Tag 
                                                onClick={()=>{
                                                    setSelectedId(item)
                                                    setOpen(true)
                                                }}
                        style={{cursor:"pointer",width:"70px",height:"25px",textAlign:"center"}}
                        color={getClassOrder(
                            item?.status
                          )}>{capitalizeFirstLetter(item?.status)}</Tag>
                                                </td>
                                            </tr>
                                        ))

                                    }
                                    <tr>

                                    </tr>
                                </tbody>
                            </Table>
                            <UsePagination links={links} onPaginate={onPaginate} />

                        </>
                        : ''
                }


            </CardBody>
        </Card>
      <ApproveReject visible={open} HideModal={()=>setOpen(false)} id={selectedId?.id} reload={()=> {
        setUpdateSlots(!updateSlots)
        getHistory(prevUrl)}}/>
        </>
        
    )
}

export default Slots