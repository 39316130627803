import { Formik, useFormik, Form, Field, } from "formik";
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from '../../../services/config';
import toast from 'react-hot-toast'
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { Input } from '../../../_metronic/_partials/controls';
import { Switch } from "@material-ui/core";

const EditVersion = ({ visible, hide, reload, selectedObject,foldable, isPublishable }) => {



useEffect(() => {
setStatus(selectedObject.status)
}, [selectedObject])

    console.log(isPublishable);

    const authToken = localStorage.getItem("userToken");

    const [addLoading, setaddLoading] = useState(false)
   const [status,setStatus]=useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };



    const initialValues = {
        version: selectedObject.version,
        // version_code: selectedObject.version_code,


    };


    const LoginSchema = Yup.object().shape({
        version: Yup.string()
            .required('Version number is required'),

            // version_code: Yup.string()
            // .required('Version number is required')
    });


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.type === "application/vnd.android.package-archive") {
            setSelectedFile(file);
          } else {
            alert("Please select a valid APK file.");
            event.target.value = ""; // Clear the input
          }
        }
      };


    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setaddLoading(true)

            const data = {
                version: values.version,
                publish: 0

            }
            console.log(data)
            // fetch(`${baseUrl}/admin/versions/${selectedObject.id}?_method=PUT`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: `Bearer ${authToken}`,
            //     },
            //     body: JSON.stringify(data)
            // }).then(res => res.json())
            //     .then((data) => {
            //         if (data.metadata.success) {
            //             toast.success(data.metadata.message);
            //             setSubmitting(false)
            //             reload()
            //             setaddLoading(false)
            //             hide()

            //         }
            //         else if (!data.metadata.success) {
            //             Object.values(data.metadata.message).map(msg => {
            //                 toast.error(msg)
            //                 setaddLoading(false)
            //                 setSubmitting(false)
            //             })
            //         }
            //         console.log(data)
            //     }).catch(err => {
            //         setSubmitting(false)
            //         setaddLoading(false)

            //         console.error("add codes error", err)
            //     })

        }
    })

    const EditHandler = (values) => {
        setaddLoading(true)


        const formData = new FormData();

        formData.append('version', values.version);
        // formData.append('version_code', values.version_code);
        formData.append('status', status ? 1: 0);

        if(selectedFile){
            formData.append('apk', selectedFile);
        }
        
        fetch(`${baseUrl}/admin/apk-versions/${selectedObject.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            body: formData
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message);

                    hide()
                    reload()
                    setaddLoading(false)

                }
                else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                        setaddLoading(false)

                    })
                }
            }).catch(err => {

                setaddLoading(false)

                console.error("add codes error", err)
            })
    }




    return (
        <Modal
            show={visible}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Apk Version</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    // validate={validatefield }
                    onSubmit={(values) => {
                        EditHandler(values)
                    }}
                >


                    {({ handleSubmit, handleChange, setValues, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (

                        <Form autoComplete='off'>
                            <div className="row">
                                <div className="col-md-12 m-2">
                                    <label>Version</label>
                                    <Field
                                        name="version"
                                        className={`form-control form-control-solid h-auto py-2 px-3 ${getInputClasses(
                                            "version"
                                        )}`}
                                        value={values.version}
                                        component={Input}
                                        placeholder="Version"

                                    />
                                    {formik.touched.version && formik.errors.version ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.version}</div>
                                        </div>
                                    ) : null}
                                </div>
                                {/* <div className="col-md-12 m-2">
                                    <label>Version Code</label>
                                    <Field
                                        name="version_code"
                                        className={`form-control form-control-solid h-auto py-2 px-3 ${getInputClasses(
                                            "version_code"
                                        )}`}
                                        value={values.version_code}
                                        component={Input}
                                        placeholder="Version_code Code"

                                    />
                                    {formik.touched.version_code && formik.errors.version_code ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.version_code}</div>
                                        </div>
                                    ) : null}
                                </div> */}

                                    <div className="col-md-8 mb-2">
                                                                <label className="">Apk File</label>
                                                                <input onChange={handleFileChange} type="file" className="form-control" accept=".apk"/>
                                                            </div>
                                                            <div className="col-md-4" style={{marginTop:"25px"}}>
                                                                <label>Status</label>
                                                                <Switch checked={status}  onChange={(e)=>setStatus(e.target.checked)}/>
                                                            </div>
                            </div>
                            <Modal.Footer>
                                <Button variant="danger" onClick={hide}>
                                    Close
                                </Button>
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`btn btn-primary`}
                                >
                                    <span style={{ textAlign: "center" }}>Update</span>
                                    {addLoading && <Spinner animation="border" />}
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}


                </Formik>

            </Modal.Body>
        </Modal>
    )
}

export default EditVersion